
@import 'primeicons/primeicons.css';
        
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ul-widget4__item {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #dee2e6;
  padding: 0px 0;
} 

.ul-widget4__item:last-child {
  border-bottom: 0;
}
.my-card{
  margin-top: 10px;
  background-color: #FFFF;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 5px;
  /* align-content: first baseline; */
  border: solid 1px #e4e9ee;
  display: flex;
  flex-direction: row;
  .my-radio-div{
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  input[type='radio']{
      height: 150px;
  }
}
.my-card:hover{
  border: none;
  background-color: #8DA9C4;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px #8DA9C4;
  .my-msg{
      font-weight: 900;
  }
}
.my-msg{
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: 700; */
}
.my-box {
  background-color: #EEF4ED;
  color: white;
  margin-right: 10px; 
  height: 60px;
  width: 60px; 
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .my-img{
      height: 30px;
      width: 30px;
  }
}
.card-checked{
  background: linear-gradient(90deg, #004D6E 0%, #00ACCC 100%); 
  color: #FFFF;
}
input[type='radio'] {
  accent-color: #004D6E;
}
.stepper-step{
  /* background: linear-gradient(90deg, #004D6E 0%, #00ACCC 100%);  */
  
}