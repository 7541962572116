body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background:"#fff";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active{
  color: var(--tp-common-white);
  border-bottom: solid 2px ;
  /* background: linear-gradient(90deg, #1D3461 0%, #00ACCC 100%); */
  /* border-color: linear-gradient(90deg, #1D3461 0%, #00ACCC 100%); */
  border-color: #1D3461;
  /* background: #eef4ed; */
  background: var(--tp-common-white);
 }
 .breadcrumb *{
  color: #1D4361;

 }
 .breadcrumb h1{
  color: #1D4361;
  font-weight: bold;
 }

 .disabled{
  pointer-events: none;
  opacity: 0.4;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  height: 35px;
}
input{
  height: 35px;
}

#m-loading {
  /* position: relative; */
  width: 100%;
  height: 100%;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}
.text-white{
  color:var(--tp-common-white);
}
.my-header{
  background: linear-gradient(90deg, #1D3461 0%, #00ACCC 100%);
  color:var(--tp-common-white);
}
.empty-row{
  background: var(--tp-common-gray);
  color: var(--tp-common-gray);
}
.label-text{
  font-size: 20;
  color: red;
  font-weight: 400;
}
.form-element{
  padding: auto;
  height: 43px;
}
.card-body {
  /* flex: 1 1 auto; */
  background-color: var(--tp-common-gray);
  padding: 1.rem;
}
.card-icon .card-body {
  padding: 2rem 0.5rem;
}
.card-icon-big .card-body {
  padding: 2rem 0.5rem;
}
.card-icon-bg .card-body {
  display: flex;
}
.card-icon-bg .card-body .content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70px;
}
.card-ecommerce-1 .card-body [class^="i-"] {
  display: block;
  font-size: 78px;
  color: #003473;
} 
.list-grid .list-item .card-body {
  display: block !important;
}
